@tailwind base;
@tailwind components;
@tailwind utilities;


/*BASIC*/
body {
    font-family:'NanumSquareNeo' !important;
   
}

button, 
input, 
optgroup, 
select, 
textarea {
    padding: 0.375rem;
    
}

input,
select {
    border:1px solid #ddd;
}


label {
    cursor: pointer;
}

/*FONT_SIZE*/
body,
body input[type="password"],
body input[type="text"],
body input[type="email"]   {

    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
}

@media (max-width: 800px) {

    body br {
        display: none;
    }
}

/*FLEX*/
.flex-ac-jc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/*CUSTOM*/

.Cglogo {
    max-width:100px;
}

.Cglogo2 {
    max-width:160px;
}

.Cgfont {
    font-family: 'CookieRun', sans-serif;
    font-weight: 700;
}

.Cgfont2 {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 700;
}

.carousel > div {
    border-radius: 0;
}

.sub_wrap {
    color: #fff;
}

.sub-bg {background: rgb(20, 22, 23);;}
.sub-bg2 {background: #D7E0E6;}
.sub-bg3 {background: #1d1e1f;}
.sub-bg4 {background:rgba(255,255,255,0.12);}
.sub-bg5 {background:rgba(255,255,255,0.25);}
.sub-bg6 {background:#F2F6F8;}
.sub-bg7 {background:#151c2b}

.z-1{z-index: 1;}

.pt-05 {
    padding-top:2px;
}

.before:before {
    content: ''; 
    display: block; 
    position: absolute; 
    left: 0; 
    top: 0; 
    width: 100%; 
    height: 100%; 
    background: rgba(0,0,0,0.7); 
    z-index: 1;
}

.before2:before {
    content: ''; 
    display: block; 
    position: absolute; 
    left: 0; 
    top: 0; 
    width: 100%; 
    height: 100%; 
    background: rgba(0,0,0,0.9); 
    z-index: 1;
}

.linear {
    background: linear-gradient(#fff 0 0) padding-box,linear-gradient(to bottom, #ff582c, #eedcdc) border-box;
    border:3px solid transparent;
}

.dot p {box-sizing:border-box; padding-left:10px; position: relative;} 
.dot p::before {content: '·'; line-height: 1; display: block; position: absolute; left: 0; font-size: 20px;}

/*CAMP*/
.icon_wrap .box {height: 400px;}
.icon_wrap .box p {min-height: 120px;}

@media (max-width: 1024px) {
    .icon_wrap .box {height: auto;}
    .icon_wrap .box p {min-height: auto;}
}

.number li,
.practice  {background: #ffffff0d;}

@media (max-width: 800px) {
    .logo figure {max-width:50%; margin:auto;}
}

.css_bg {background-image:url(../src/Images/Camp/camp3.jpg); background-size:2000px; background-position-x:0px; background-position-y:-550px; background-repeat: no-repeat;}
.css_bg:before {background: rgba(0,0,0,0.85);}

.bg_opacity {background: #ffffff9d;}

ul.star{position: relative;}
ul.star::before{content:''; position: absolute; display: block; left: 50%; top: 27%; transform: translate(-50%,0); width: 85%; height: 1px; background: #ddd; z-index:1;}
ul.star li{position: relative; z-index: 2;}
ul.star li > div {background: #fff;}

@media (max-width: 640px) {
    ul.star::before {top:19%;}
}

@media (max-width: 640px) {
    .css_bg {background-size: 1100px; background-position-x:-400px; background-position-y:-150px;}
}

.accordion button, 
.accordion .divide-y {background: #fff;}
.accordion svg {color:#ff582c;}

.price .sale {
    position: relative; 
    display: inline-block; 
    color: gray;
}
/* .price .sale:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 100%;
    height: 2px;
    background: gray;
} */
.youtube .slider_wrap {box-sizing:border-box; padding:0 50px;}
.youtube iframe {height: 250px;}
/* .youtube .slick-prev:before, 
.youtube .slick-next:before {color:black !important;} */
.youtube .slick-next {right:-50px;}
.youtube .slick-prev {left:-50px;}

.youtube .slick-dots li.slick-active button:before {color: white;}
.youtube .slick-dots li button:before {color: #fff;}
@media (max-width: 800px) {
    .youtube iframe {height: 50vw;}
}

/*FIXED BUTTON*/
.fixed_wrap {
    position: fixed;
    width: 100%;
    height:75px;
    left: 50%;
    transform: translate(-50%,0);
   
}
.fixed_bt {
    -webkit-box-pack: justify;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    width: 70%;
    height: 75px;
    max-width: 614px; 
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: rgb(255, 255, 255);
}
.fixed_bt h1 {
    -webkit-box-align: center;
    align-items: center;
    line-height: 150%;
}
.fixed_bt h1 span {
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    font-weight: 700;
    padding-top: 2px;
}
.fixed_bt a {
    border: none;
    font-style: normal;
    font-size: 12px;
    line-height: 150%;
    font-weight: 700;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

/*피드백 요청사항*/
.feedback_tab .text-blue-600 {
    color: rgba(255, 88, 44,1);
}
.feedback_tab .border-blue-600{
    border-color: rgba(255, 88, 44,1);
}
.feedback_tab .text-sm{
    font-size: 0.75rem;
    line-height: 1rem;
}

/*
sub_var
서브페이지 공통 타이틀 네비게이션
*/
.sub_var ul li {
    position: relative;
}
.sub_var ul li::before {
    content: '/';
    display: block;
    position: absolute;
    top:50%;
    right: -13px;
    transform: translate(-0,-50%);
}
.sub_var ul li:last-child::before {
    display: none;
}

/*전문가 찾기*/
.common_tab .MuiBox-root {
    padding: 0;
    
}

.common_tab .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.common_tab .css-ahj2mt-MuiTypography-root,
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root,
.css-1k23hlb-MuiButtonBase-root-MuiButton-root {
    font-family: 'NanumSquareNeo', sans-serif !important;

}

.common_tab .MuiTab-textColorPrimary {color: #fff;}
.common_tab2 .MuiTab-textColorPrimary {color: #000; font-size: 0.75rem; box-sizing: border-box;}
.common_tab2 .MuiTabs-flexContainer {border-bottom: 1px solid #ddd;}

.common_tab .Mui-selected {
    color: rgba(255, 88, 44,1) !important;
   
}
.common_tab .MuiTabs-indicator {

   background: rgba(255, 88, 44,1) !important;
}

.line-clamp3 {
    white-space: normal;
    display:-webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient:vertical;
    overflow: hidden;
}

/* pagination */
.pagination button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; 
    height: 30px;
}
.pagination .current button {
    color: rgba(255, 88, 44,1);
}


/*검색창*/
.search_bar  * {
    height: 40px;
}


/*MAIN SLICK*/
.slick-initialized .slick-slide {
    line-height: 0;
}


/*CAMP MENU*/
.Camp_menu .current{
    opacity: 1;
    font-weight: 700;
}
.Camp_menu .current::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: rgba(255, 88, 44,1);
    
}

/*RESERVATION*/
.reservation_table_wrap {}
.reservation_table_wrap > div {}
.reservation_table_wrap  th {text-align: center; padding: 10px 0;}
.reservation_table_wrap  td {text-align: center; margin:auto; padding: 10px 0;}
.button_table_wrap {width: 120px;}


.reservation_slick .slick-list > div > div > div > div {display: flex !important; align-items: center; justify-content:center;}
.reservation_slick img {max-height:500px;}
.reservation_slick .slick-dots {bottom: 10px;}
.reservation_slick .slick-prev {left: 0px; z-index: 1;}
.reservation_slick .slick-next {right: 0px;}

/*ClassAudition*/
.stepCircle {position: relative;}
.stepCircle:before {content: ''; display: block; position: absolute; left:50%; transform: translate(-50%,0); width: 2px; height: 100%; background: #ddd;}
.stepCircle .rounded-full {position: relative; margin-right: 40px;}
.stepCircle .rounded-full::before {content: ''; display: block; position: absolute; right: -20px; top: 50%; transform: translate(0,-50%); width: 20px; height: 2px; background: #ddd;}
.stepCircle .rounded-full::after {content: ''; display: block; position: absolute; right: -23px; top: 50%; transform: translate(0,-50%); width: 6px; height: 6px; background: #000; border-radius:50%;}

.dotUl li {position: relative;}
.dotUl li::before {content: ''; display: block; position: absolute; left: 50%; top: 0; width: 1px; height: 70%; border: 1px dashed rgba(0,0,0,0.3); z-index: 1;}
.dotUl li:nth-child(2):before {border: 1px dashed rgba(0,0,0,0.5);}
.dotUl li:nth-child(3):before {border: 1px dashed rgba(0,0,0,0.7);}
.dotUl li div {position: relative; z-index: 1;}
.dotUl li div.white {background: #fff;}
.dotUl li .bg-Cgcolor {background: linear-gradient(90deg, rgba(255,88,44,1) 35%, rgba(255,111,0,0.8071603641456583) 100%);}
.dotUl li:nth-child(2) .bg-Cgcolor {background: linear-gradient(90deg, rgba(255,88,44,1) 35%, rgba(255,111,0,0.6951155462184874) 100%);}
.dotUl li:nth-child(3) .bg-Cgcolor {background: linear-gradient(90deg, rgba(255,88,44,1) 35%, rgba(255,111,0,1) 100%);}

.shake {animation: shakeTop 2s infinite linear;}
@keyframes shakeTop {
    0%,50%{transform:rotate(0deg);transform-origin:50% 0;}
    5%,45%{transform:rotate(2deg)}
    10%,20%,30%{transform:rotate(-4deg)}
    15%,25%,35%{transform:rotate(4deg)}
    80%{transform:rotate(-2deg)}
}

@media (max-width: 720px) {

    .ClassAudition .text-3xl {font-size:1.25rem;}
    .stepCircle .rounded-full::before {right: -36px; width: 36px;}
    .stepCircle .rounded-full::after {right: -39px; width: 6px;}

}


/*STORE CATEGORY*/
.storecate > div {display: flex; flex-wrap: wrap;}
.storecate > div > a {width: 48%; margin-right: 3.5%; margin-bottom:50px;}
.storecate > div > a:nth-child(2n) {margin-right: 0;} 

.storecate > div > a .img_box {position: relative;}
.storecate > div > a .img_box.soldout:before {display: block; content:''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: #000; opacity: 0.5;}


@media (min-width: 720px) {
   
    
}

/*DELIVERY TABLE*/
.defaultchek > div {background: #000; width: 80px; text-align: center; padding: 5px; margin-bottom: 10px;}
@media (max-width: 720px) {
    .delivery_table {table-layout: fixed;}
    .delivery_table * {display: block; text-align: left; table-layout: fixed;}
    .delivery_table button {justify-content: flex-end;}
    .delivery_table .w-full > div {width: 100%;}

}

.abstract {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}